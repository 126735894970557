import React from 'react';
import "../Testimonials/Reviews.css";
import { Button, Form, Input, Select, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';

const Reviews = () => {
    const onFinish = async(values) => {
        // Here you can add further logic to handle submission to your backend or other actions
        const formDataToSend = new FormData();
        try {
            // Append data to FormData object
            formDataToSend.append('test_name', values.name);
            formDataToSend.append('test_content', values.review);
            formDataToSend.append('action', "add");
            // Send FormData object to API endpoint
            const response = await axios.post('https://skdm.in/server/bhavinjavari/testimonals.php', formDataToSend);
            console.log('Response from API:', response.data);
            message.success('Review Added Successfully');
          } catch (error) {
            console.error('Error submitting form:', error);
          }
    };

    return (
        <>
            <section className="profile-reviews-section">
                <div className="profile-reviews-section-main-con">
                    <div className='profile-reviews-head-con'>
                        <h2>Write Review Here</h2>
                    </div>
                    <div className='profile-reviews-section-details-con'>
                        <div className='profile-reviews-section-details-form'>
                            <Form layout="vertical" onFinish={onFinish}>
                                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter your name!' }]}>
                                    <Input placeholder="Enter name" />
                                </Form.Item>

                                <Form.Item label="Review Message" name="review" rules={[{ required: true, message: 'Please enter your review!' }]}>
                                    <TextArea rows={6} />
                                </Form.Item>
                                
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Reviews;
