import React, { useRef, useState } from 'react';
import "../Home/HomeNewArrival.css";
import StockData from '../StockData';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
const HomeNewArrival = () => {
    return (
        <>
            <section className='new-arrival-section-1'>
                <div className='container'>
                    <div className='new-arrival-section-main-con'>
                        <div className='home-section-3-heading-con'>
                            <h2><span>New</span> Arrival</h2>
                            <p>"Discover the latest trends with our fresh arrivals! Dive into new and exclusive stocks, offering the best picks just for you. Don't miss out—explore now and stay ahead!"</p>
                        </div>

                        <div className='new-arrival-section-details-main-con'>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, Pagination]}
                                className="mySwiper"
                            >
                                {
                                    StockData.map((elem) => {
                                        return (
                                            <>
                                                <SwiperSlide>
                                                    <div className='stock-card-main-con'>
                                                        <div className='stock-card-image-con'>
                                                            <img src={elem.Simage} alt='nse' />
                                                        </div>
                                                        <div className='stock-card-details-con'>
                                                            <h3>{elem.name}</h3>
                                                            <h4>₹6500</h4>
                                                            <div className='stock-card-details-info-con'>
                                                                <h5>( {elem.profit} )</h5>
                                                                <h5>( {elem.profitpercentage}% )</h5>
                                                                <h6>{elem.some}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            </>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeNewArrival