import nse from "../Images/Stock/nse.jpeg";
import BharatNidhi from "../Images/Stock/BharatNidhi.jpg";
import hdb from "../Images/Stock/hdb.jpeg";
import Polymatech from "../Images/Stock/Polymatech.png";
import rrp from "../Images/Stock/rrp.png";
import oyo from "../Images/Stock/oyo.jpeg";


const StockData = [
    {
        id: '',
        name:'NSE India Limited Unlisted Shares',
        price:'6500',
        profit:'0.00',
        profitpercentage:'0%',
        some:'15D',
        Simage:nse,
    },
    {
        id: '',
        name:'Polymatech Unlisted Shares',
        price:'950',
        profit:'100.00',
        profitpercentage:'11.76%',
        some:'15D',
        Simage:Polymatech,
    },
    {
        id: '',
        name:'Camac Commercial Company Limited Unliste ...',
        price:'35000',
        profit:'0.00',
        profitpercentage:'0%',
        some:'15D',
        Simage:hdb,
    },
    {
        id: '',
        name:'Bharat Nidhi (Bharat Bank) Unlisted Shar ...',
        price:'9000',
        profit:'0.00',
        profitpercentage:'0%',
        some:'15D',
        Simage:BharatNidhi,
    },
    {
        id: '',
        name:'Waaree Energies Unlisted Shares',
        price:'2250',
        profit:'75.00',
        profitpercentage:'3.45%',
        some:'15D',
        Simage:rrp,
    },
    {
        id: '',
        name:'ORAVEL STAYS LIMITED (Oyo Unlisted Share ...',
        price:'45',
        profit:'-3.00',
        profitpercentage:'-6.25%',
        some:'15D',
        Simage:oyo,
    },
];

export default StockData;