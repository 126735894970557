import React, { useEffect, useState } from 'react';
import "../Css/StockDetails.css";
import { Button, Form, Input } from 'antd';
import rrp from "../Images/Stock/rrp.png";
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const StockDetails = () => {

    const { stockSlugs } = useParams()
    const [stockDetails, setStockDetails] = useState(null)

    // console.log("stockSlugs", stockSlugs)
    const fetchCategoryData = async () => {
        try {
            const response = await axios.get(`https://skdm.in/server/bhavinjavari/stock.php?action=fetchStockDataBySlug&slug=${stockSlugs}`);
            console.log("response", response)
            setStockDetails(response.data.stocks[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    // console.log("stockDetails", stockDetails)
    useEffect(() => {
        const renderTime = setTimeout(() => {
            fetchCategoryData();
        }, 500);

        return ()=> clearTimeout(renderTime);
    }, [stockSlugs]);


    return (
        <>
            <section className="ProductList-Section-1 details-banner">
                <div className="container">
                    <div className="ProductList-Section-con">
                        <div className="ProductList-Section-Text">
                            <div className="ProductList-Section-NavPage">
                                <span>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 1024 1024"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                                    </svg>
                                    Home
                                </span>
                                <h3>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path fill="none" d="M0 0h24v24H0V0z" />
                                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                                    </svg>
                                </h3>
                                <h2>Stock Details</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='stock-details-section-2'>
                <div className='stock-details-section-2-con'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='stock-details-section-2-details-main-con-Info'>
                                <div className='stock-details-section-2-details-image-name-con'>
                                    <div className='stock-details-section-2-details-image'>
                                        <img src={rrp} alt='rrp' />
                                    </div>
                                    <h2>{stockDetails && stockDetails?.stock?.name}</h2>
                                    <div className='stock-details-section-2-details-price'>
                                        <h3>₹{stockDetails && stockDetails?.stock?.price}</h3>
                                        <h4>(453.00)</h4>
                                        <h4>(100.00%)</h4>
                                        <h5>3Y</h5>
                                    </div>
                                </div>

                                <div className='stocks-details-section-2-main-con'>
                                    <div className='stocks-details-section-2-con'>
                                        <h2>About {stockDetails && stockDetails?.stock?.name} {stockDetails && stockDetails?.stock?.stock_type} Shares</h2>
                                        <h3><span>1. Introduction:</span>  {stockDetails && stockDetails?.aboutStock[0]?.Introduction} </h3>
                                        <h3><span>2. Previous Ownership and Acquisitions:</span>{stockDetails && stockDetails?.aboutStock[0]?.Previous_Ownership_and_Acquisitions}</h3>
                                        <h3><span>3. Post-Acquisition Transformation: </span> {stockDetails && stockDetails?.aboutStock[0]?.Post_Acquisition_Transformation}</h3>
                                        <h3><span>4. Financial Performance:</span>  {stockDetails && stockDetails?.aboutStock[0]?.Financial_Performance} </h3>
                                        <h3><span>5. Manufacturing Capacity Expansion:</span>  {stockDetails && stockDetails?.aboutStock[0]?.Manufacturing_Capacity_Expansion} </h3>
                                        <h3><span>6. Shareholding and Valuation: </span> {stockDetails && stockDetails?.aboutStock[0]?.Shareholding_and_Valuation} </h3>
                                        <h3><span>7. Management and Board Structure: </span> {stockDetails && stockDetails?.aboutStock[0]?.Management_and_Board_Structure} </h3>
                                        <h3><span>8. Future Prospects: </span> {stockDetails && stockDetails?.aboutStock[0]?.Future_Prospects} </h3>
                                        <h3><span>9. Conclusion: </span>  {stockDetails && stockDetails?.aboutStock[0]?.Conclusion} </h3>
                                    </div>
                                </div>
                                <div className='stocks-details-section-2-fundamental'>
                                    <h2>Fundamentals</h2>
                                    <div className='stocks-details-section-2-fundamental-details'>
                                        <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1'>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>{stockDetails && stockDetails?.stock?.name} {stockDetails && stockDetails?.stock?.stock_type} Shares Price</h3>
                                                    <h4>₹ {stockDetails && stockDetails?.stock?.price} <span> (Per Equity Share) </span></h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Lot Size</h3>
                                                    <h4>{stockDetails && stockDetails?.stockFundamental[0]?.Lot_Size} Shares</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>52 Week Hight</h3>
                                                    <h4>₹ {stockDetails && stockDetails?.stockFundamental[0]?.Week_Hight}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Depository</h3>
                                                    <h4>{stockDetails && stockDetails?.stockFundamental[0]?.Depository}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>PAN Number</h3>
                                                    <h4>{stockDetails && stockDetails?.stockFundamental[0]?.PAN_Number}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>ISIN Number</h3>
                                                    <h4>{stockDetails && stockDetails?.stockFundamental[0]?.ISIN_Number}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Market Cap (in cr.)</h3>
                                                    <h4>₹ {stockDetails && stockDetails?.stockFundamental[0]?.Market_Cap}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>P/E Ratio</h3>
                                                    <h4>{stockDetails && stockDetails?.stockFundamental[0]?.P_E_Ratio}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>P/B Ratio</h3>
                                                    <h4>{stockDetails && stockDetails?.stockFundamental[0]?.P_B_Ratio}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Debt to Equity</h3>
                                                    <h4>{stockDetails && stockDetails?.stockFundamental[0]?.Debt_to_Equity}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>ROE (%)</h3>
                                                    <h4>{stockDetails && stockDetails?.stockFundamental[0]?.ROE}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Book Value</h3>
                                                    <h4>{stockDetails && stockDetails?.stockFundamental[0]?.Book_Value}</h4>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='stocks-details-section-2-fundamental-points'>
                                                    <h3>Face Value</h3>
                                                    <h4>{stockDetails && stockDetails?.stockFundamental[0]?.Face_Value}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='stocks-details-section-2-btn-con'>
                                    <button className='buy-btn'>
                                        buy
                                    </button>
                                    <button className='sell-btn'>
                                        sell
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='stock-details-section-2-right-side-main-con'>
                                <div className='stock-details-2-right-side-form'>
                                    <div className='stock-details-2-right-side-form-title'>
                                        <h3>{stockDetails && stockDetails?.stock?.name} {stockDetails && stockDetails?.stock?.stock_type} Shares</h3>
                                        <h4>{stockDetails && stockDetails?.stock?.price}/- Rs</h4>
                                    </div>
                                    <div className='stock-details-2-right-side-form-price'>
                                        <div className='stock-details-2-form-price-and-quent'>
                                            <Form layout='vertical'>
                                                <Form.Item name="name" label="Name">
                                                    <Input
                                                        placeholder='Name'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="email" label="Email">
                                                    <Input
                                                        placeholder='Email'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="contact" label="Contact">
                                                    <Input
                                                        placeholder='Contact No.'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="quantity" label="Quantity">
                                                    <Input
                                                        placeholder='quantity'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="price" label="Price">
                                                    <Input
                                                        placeholder='Price'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="message" label="Message">
                                                    <Input.TextArea
                                                        placeholder='Message'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="totalprice" label="Total Price">
                                                    <Input
                                                        placeholder='Total Price'
                                                    />
                                                </Form.Item>
                                                <Form.Item name="cdsl" label="CDSL / NSDL">
                                                    <Input
                                                        placeholder='Total Price'
                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Link>
                                                        <Button className='stock-details-buy-btn'>
                                                            Buy
                                                        </Button>
                                                    </Link>
                                                    <Link>
                                                        <Button className='stock-details-sell-btn'>
                                                            Sell
                                                        </Button>
                                                    </Link>
                                                </Form.Item>
                                            </Form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className='stock-details-faq-section-con-main'>
                <div className='stock-details-faq-section-con'>
                    <div className='stock-details-faq-section-heading'>
                        <h2>Frequently Asked <span>Questions</span></h2>
                        <p>Find answers to common questions that you may have in your mind.</p>
                    </div>

                    <div className='stock-details-faq-section-drop'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Q.1 : How to buy Polymatech Unlisted Shares?</Accordion.Header>
                                <Accordion.Body>
                                    <div className='stock-details-faq-section-drop-Body'>
                                        <p>Please find below the procedure for buying Polymatech Unlisted Shares at UnlistedZone.</p>
                                        <p>1. You confirm booking of Polymatech Unlisted Shares Unlisted Shares with us at a trading price.</p>
                                        <p>2. You provide your client master report (ask the broker if not available) along with PAN Card and Cancelled Cheque in case you are not transferring funds from the bank account as mentioned in the CMR Copy. These are KYC documents required as per SEBI regulations.</p>
                                        <p>3. We Will Provide the Bank details. You need to transfer funds to that account.</p>
                                        <p>4. Payment has to be done in RTGS/NEFT/IMPS CHEQUE TRANSFER. No CASH DEPOSIT.</p>
                                        <p>5. Payment has to be done from the same account in which shares are to be credited.</p>
                                        <p><span>Note : Please note that the lock-in period for selling Polymatech Unlisted Shares Unlisted Shares is 6 months after listing. Hence, you can’t sell Polymatech Unlisted Shares Unlisted Shares which you bought in Pre-IPO for 6 months after its listing. i.e., You can sell it only after 6 months calculated from the listing date. For any queries, please contact us at </span></p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Q.2 : How to sell Polymatech Unlisted Shares?</Accordion.Header>
                                <Accordion.Body>
                                    <div className='stock-details-faq-section-drop-Body'>
                                        <p>Please find below the procedure for selling Polymatech Unlisted Shares at UnlistedZone.</p>
                                        <p>1. We will confirm our buying price of Polymatech Unlisted Shares.</p>
                                        <p>2. We will give you our client master report and you will transfer Polymatech Unlisted Shares to our demat account.</p>
                                        <p>3. We will ask for your bank details once Polymatech Unlisted Shares are received in our demat account.</p>
                                        <p>4. We will transfer the funds to your bank account within 24 hrs of receiving Polymatech Unlisted Shares.</p>
                                        <p>5. Payment will be made in RTGS / NEFT / CHEQUE TRANSFER/IMPS. No CASH DEPOSIT.</p>
                                        <p>6. Payment will be given in the same account which is linked to the demat account or you need to provide the cancelled cheque showing your name to verify. As per SEBI regulations, the transfer of funds to a third-party account is not legal and our policy refrains us from doing so. </p>
                                        <p><span>Note :</span> The price at which we are buying is fixed for 3 days. If you can't sell your stock within 3 days, then the price of that day will be applicable when we receive the shares in our demat.</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Q.3 : What is the lock-in period of Polymatech Unlisted Shares?</Accordion.Header>
                                <Accordion.Body>
                                    <div className='stock-details-faq-section-drop-Body'>
                                        <p>The lock-in period for Polymatech Unlisted Shares varies depending on the category of investors:</p>
                                        <p>1. For Venture Capital Funds or Foreign Venture Capital Investors, there is a lock-in period of 6 months from the date of acquisition of Polymatech Unlisted Shares.</p>
                                        <p>2. For AIF-II (Alternative Investment Funds - Category II), there is no lock-in period.</p>
                                        <p>3. For other types of investors, which include Retail Investors, High Net-worth Individuals (HNIs), or Body Corporates, the lock-in period is 6 months from the date of the IPO listing of Polymatech Unlisted Shares.</p>
                                        <p><span>Note: </span>This regulation was introduced by SEBI in August 2021. The rule change, which reduced the lock-in period from one year to six months, was aimed at encouraging more investments in startups that are preparing for public offerings or IPOs. This reduction in the lock-in period is seen as a significant step forward, and since its introduction, many Portfolio Management Services (PMS) have been advising their clients to invest in Pre-IPO shares to capitalize on the benefits of early-stage investments.</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Q.4 : How is DIS used to sell Polymatech Unlisted Shares?</Accordion.Header>
                                <Accordion.Body>
                                    <div className='stock-details-faq-section-drop-Body'>
                                        <p>DIS, or Delivery Instruction Slip, is a tool used by investors to sell or transfer Polymatech Unlisted Shares from their demat account to another. There are two types of DIS Methods: </p>
                                        <p><span>1. Offline-DIS: </span> : This is a traditional, paper-based method for transferring shares. When using Offline-DIS, investors are required to fill out a DIS form and submit it to their broker. The necessary fields in the form include: </p>
                                        <p>a. ISIN number of Polymatech Unlisted Shares. </p>
                                        <p>b. Name of Polymatech Unlisted Shares. </p>
                                        <p>c. Quantity of Polymatech Unlisted Shares. </p>
                                        <p>d. Consideration Amount. </p>
                                        <p>e. Target DP ID and Client ID. </p>
                                        <p>f. Annexure. </p>
                                        <p><span>2. Online DIS:</span> : Some brokers offer the facility to transfer Polymatech Unlisted Shares through an online DIS system. It's advisable to check with your broker if such a facility is available. </p>
                                        <p>For instance, platforms like Angel Broking provide an Online-DIS feature. In this method, an investor simply needs to add a beneficiary and transfer Polymatech Unlisted Shares by filling in details similar to those required in the Offline-DIS. </p>
                                        <p>For a more comprehensive understanding of this process, you can refer to our detailed article: https://unlistedzone.com/how-do-i-sell-my-unlisted-shares/</p>

                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Q.5 : Minimum Ticket Size for investment in Polymatech Unlisted Shares?</Accordion.Header>
                                <Accordion.Body>
                                    <div className='stock-details-faq-section-drop-Body'>
                                        <p>In recent years, the unlisted share market has expanded significantly, leading to a reduction in the minimum investment amount. Previously, the typical investment ticket size ranged from 5-10 Lakhs, but in the current market scenario, it has decreased to between 35-50k. Therefore, through our UnlistedZone platform, if someone wishes to invest in Polymatech Unlisted Shares, the minimum investment required would now be in the range of 35-50k</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Q.6 : Is buying Polymatech Unlisted Shares legal in India?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, buying and selling unlisted shares in India is indeed 100% legal. This activity is regulated and governed under the guidelines provided by the Securities and Exchange Board of India (SEBI). Investors and traders must adhere to these regulations and guidelines to ensure compliance with legal and financial standards. It's important for participants in the unlisted share market to be aware of and understand these regulations to engage in transactions legally and securely
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default StockDetails