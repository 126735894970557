import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUserToken } from "../../features/authSlice";
import { setProfile, storeToken } from "../../services/localStorageServices";
import { Link, useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Form, Input, message } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Logo from "../../Images/Logo.png";
import OTPInput from "react-otp-input";
import "../../Css/account.css";

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false); // State to manage OTP send status
  const [otp, setOtp] = useState('');
  const [userEmail, setUserEmail] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('action', 'login');
      formData.append('user_email', values.user_email);
      setUserEmail(values.user_email)
      const response = await axios.post('https://skdm.in/server/bhavinjavari/user.php', formData);
      if (response) {
        message.success('OTP sent successfully');
        setOtpSent(true); // Set OTP sent status to true
      } else {
        message.error('Failed to submit form');
        console.error('Failed to send data to the API.');
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOtpVerify = async () => {
    try {
      const formData = new FormData();
      formData.append('action', 'verify_login');
      formData.append('otp', otp);
      formData.append('user_email', userEmail);
      const response = await axios.post(
        "https://skdm.in/server/bhavinjavari/user.php", formData
      );

      if (response) {
        message.success('OTP verified successfully');
        console.log("response",response)
        dispatch(setUserToken({ token: response.data.token }));
        storeToken(response.data.token);
        setProfile(response.data.profile);
        navigate("/")
        // Redirect to dashboard or perform other actions upon successful OTP verification
      } else {
        message.error('Failed to verify OTP');
        console.error('Failed to verify OTP.');
      }

    } catch (error) {
      message.error(error.response);
      console.error('Error verifying OTP:',error);
    }
  };

  return (
    <>
      <div className="Register-main-con">
        <div className="container">
          <div className="Register-con">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="Login-images-main-con">
                  <div className="Login-image-logo-con">
                    <img src={Logo} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex p-0 align-items-center justify-content-start">
                <div className="account-card">
                  <div className="title">
                    <h1>Sign In</h1>
                    <Breadcrumb
                      items={[
                        {
                          title: <Link to="/">Home</Link>,
                        },
                        {
                          title: "Sign In",
                        },
                      ]}
                    />
                  </div>
                  <div className="account-form">
                    <Form
                      name="normal_login"
                      className="login-form"
                      layout="vertical"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="user_email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email!",
                          },
                        ]}
                      >
                        <Input
                          prefix={<UserOutlined className="site-form-item-icon" />}
                          placeholder="Email"
                        />
                      </Form.Item>

                      {otpSent && (
                        <div className="col-12">
                          <div className="SignUp-section-otp-main-con">
                            <div className='Forgot-mail-otp-con-details-Info-Input'>
                              <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} />}
                              />
                            </div>
                            <div className='Code-expires-con'>
                              <h4>Code Expires in: <span>03:12</span></h4>
                            </div>
                          </div>
                        </div>
                      )}

                      <Form.Item>
                        {!otpSent ? (
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            loading={loading}
                          >
                            Get OTP
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            htmlType="button"
                            className="login-form-button"
                            loading={loading}
                            onClick={handleOtpVerify}
                          >
                            Verify OTP
                          </Button>
                        )}
                        <br />
                        Or <Link to="/account/sign-up">register now!</Link>
                      </Form.Item>

                      <Form.Item>
                        <div className="Google-Login-btn-con">
                          {/* Google login button */}
                        </div>
                      </Form.Item>

                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
