import React from 'react';
import "../Css/About.css";
import homeabout01 from "../Images/Home/demo profile.avif";
const About = () => {
    return (
        <>
            <section className="ProductList-Section-1 about-banner">
                <div className="container">
                    <div className="ProductList-Section-con">
                        <div className="ProductList-Section-Text">
                            <div className="ProductList-Section-NavPage">
                                <span>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 1024 1024"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                                    </svg>
                                    Home
                                </span>
                                <h3>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path fill="none" d="M0 0h24v24H0V0z" />
                                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                                    </svg>
                                </h3>
                                <h2>About Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="home-about-section-con">
                <div className="container">
                    <div className="about-section-image-details">
                        <div className="row">
                            <div className="col-md-6 p-0">
                                <div class="about-us-one-left">
                                    <img src={homeabout01} alt="Custom Interior Designers in Andheri" />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="about-section-page-about-details-con">
                                    <div className="about-section-page-about-details">
                                        <div className="about-section-page-about-info">
                                            <h3>About Founder</h3>
                                            <p>
                                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse, dolor voluptatibus cum amet mollitia cupiditate, ipsam nemo eaque obcaecati modi quo dolorum voluptatem illo commodi quod molestias voluptates maxime minima reprehenderit perferendis possimus. Quas sint deleniti suscipit, quaerat eligendi dignissimos.
                                            </p>
                                            <p>
                                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse, dolor voluptatibus cum amet mollitia cupiditate, ipsam nemo eaque obcaecati modi quo dolorum voluptatem illo commodi quod molestias voluptates maxime minima reprehenderit perferendis possimus. Quas sint deleniti suscipit, quaerat eligendi dignissimos.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='About-Section-Mission-Main-con'>
                <div className='container'>
                    <div className='About-Section-Mission-con'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='About-Section-Deatils-con'>
                                    <h2>01</h2>
                                    <h3>REAL TIME EXECUTION</h3>
                                    <p>Best in Class execution speed - Offer Note to Deal execution (transfer of funds and shares) happens within 24 hours</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='About-Section-Deatils-con'>
                                    <h2>02</h2>
                                    <h3>BEST PRICES IN THE INDUSTRY</h3>
                                    <p>We provide a buy-sell quote on every trade. Huge client base and massive volumes help us to provide best rates in the industry</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='About-Section-Deatils-con'>
                                    <h2>03</h2>
                                    <h3>GUIDED SIMPLIFIED PROCESS</h3>
                                    <p>Over the years our best in class process has been simplified. Additionally, our associates will hand hold you through every step of the way</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='About-Section-Deatils-con'>
                                    <h2>04</h2>
                                    <h3>TRUSTED BRAND</h3>
                                    <p>We are regularly featured in top media outlets like Economic Times, Financial Express, Moneycontrol. Also read our verified client reviews on Google</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='About-Section-Deatils-con'>
                                    <h2>05</h2>
                                    <h3>IN DEPTH RESEARCH & ANALYSIS</h3>
                                    <p>Our analysts are adept at finding investment opportunities and back those up with fundamental and industry analysis.</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='About-Section-Deatils-con'>
                                    <h2>06</h2>
                                    <h3>NO HIDDEN/SERVICE CHARGES</h3>
                                    <p>We charge no brokerage / commission / service charges on our sale or purchase transactions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className='our-vision'>

            </section>
        </>
    )
}

export default About