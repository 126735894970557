import React from 'react';
import "../../Css/Profiles.css";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FaUserEdit } from "react-icons/fa";
import { FaBoxOpen } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import ProfileEdit from './ProfileEdit';
import Reviews from '../Testimonials/Reviews';
import RequestData from '../Order/RequestData';
import ProfileLogout from '../LogOut/ProfileLogout';
import { LuLogOut } from "react-icons/lu";
const Profiles = () => {
    return (
        <>
            {/* ---------------- Section 1 ---------------------- */}
            <section className='Profiles-Section-1'>
                <div className='container'>

                    <div className='Profiles-Section-con'>
                        <div className='Profiles-Section-Text'>
                            <h2>
                                Account
                            </h2>
                            <div className='Profiles-Section-NavPage'>
                                <span><AiFillHome />Home</span>
                                <h3><MdKeyboardArrowRight /></h3>
                                <h1 className="d-none"></h1>
                                <h2>Account</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* ------------------------ Section 2 ---------------------------- */}
            <section className='Profile-Section-2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-12'>
                            <div className='Profile-Section-Sidebar'>
                                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <button class="nav-link active" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"><FaUserEdit />Profile</button>
                                    <button class="nav-link" id="v-pills-MyOrder-tab" data-bs-toggle="pill" data-bs-target="#v-pills-MyOrder" type="button" role="tab" aria-controls="v-pills-MyOrder" aria-selected="false"><FaBoxOpen />My Order</button>
                                    <button class="nav-link" id="v-pills-reviews-tab" data-bs-toggle="pill" data-bs-target="#v-pills-reviews" type="button" role="tab" aria-controls="v-pills-reviews" aria-selected="false"><MdMessage />Reviews</button>
                                    <button class="nav-link" id="v-pills-logout-tab" data-bs-toggle="pill" data-bs-target="#v-pills-logout" type="button" role="tab" aria-controls="v-pills-logout" aria-selected="false"><LuLogOut />Log Out</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-12'>
                            <div className='Profile-Section-Tabs'>
                                <div class="tab-content" id="v-pills-tabContent">
                                    <div class="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"><ProfileEdit /></div>
                                    <div class="tab-pane fade show active" id="v-pills-reviews" role="tabpanel" aria-labelledby="v-pills-reviews-tab"><Reviews /></div>
                                    <div class="tab-pane fade" id="v-pills-MyOrder" role="tabpanel" aria-labelledby="v-pills-MyOrder-tab"><RequestData /></div>
                                    <div class="tab-pane fade" id="v-pills-logout" role="tabpanel" aria-labelledby="v-pills-logout-tab"><ProfileLogout /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Profiles