import React, { useEffect, useState } from 'react';
import "../Home/HomeCategory.css";
import airplain from "../../Images/airplane.png";
import Agriculture from "../../Images/Category/Agriculture.png";
import AgroChemicals from "../../Images/Category/Agro Chemicals.png";
import Airpot from "../../Images/Category/airpot.png";
import AlcoholicBeverages from '../../Images/Category/Alcoholic Beverages.png';
import ApparelAndFashion from "../../Images/Category/Apparel And Fashion.png";
import AutoAncillanires from "../../Images/Category/Auto Ancillanires.png";
import axios from 'axios';

const HomeCategory = () => {

    const [categoryData, setCategoryData] = useState([])
    // console.log("categoryData",categoryData)
    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const response = await axios.get('https://skdm.in/server/bhavinjavari/category.php?action=categoryFetch');
                setCategoryData(response.data.list);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchCategoryData();
    }, []);

    return (
        <>
            <section className='home-section-category-main-con'>
                <div className='container'>
                    <div className='home-section-category'>
                        <div className='home-section-category-text'>
                            <h2>Top Sectors in <span>Unlisted Market</span></h2>
                            <p>Discover and Navigate the Leading Sectors that are Shaping the Unlisted Market Landscape Today.</p>
                        </div>
                        <div className='home-section-category-section-card-con'>
                            <div className='row'>
                                {
                                    categoryData.map((elem) => {
                                        const { Category_Id, Category_Img, category_name } = elem
                                        return (
                                            <>
                                                <div className='col-md-2'>
                                                    <div className='home-section-category-section-card'>
                                                        <div className='home-section-category-section'>
                                                            <div className='home-section-category-image-con'>
                                                                <div className='home-section-category-image'>
                                                                    <img src={Category_Img} alt='airplain' />
                                                                </div>
                                                            </div>
                                                            <h3>{category_name}</h3>
                                                            <h4>{Category_Id}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                <div className='col-md-2'>
                                    <div className='home-section-category-section-card'>
                                        <div className='home-section-category-section'>
                                            <div className='home-section-category-image-con'>
                                                <div className='home-section-category-image'>
                                                    <img src={AgroChemicals} alt='airplain' />
                                                </div>
                                            </div>
                                            <h3>Agro Chemicals</h3>
                                            <h4>5</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className='home-section-category-section-card'>
                                        <div className='home-section-category-section'>
                                            <div className='home-section-category-image-con'>
                                                <div className='home-section-category-image'>
                                                    <img src={Airpot} alt='airplain' />
                                                </div>
                                            </div>
                                            <h3>Airpot</h3>
                                            <h4>5</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className='home-section-category-section-card'>
                                        <div className='home-section-category-section'>
                                            <div className='home-section-category-image-con'>
                                                <div className='home-section-category-image'>
                                                    <img src={AlcoholicBeverages} alt='airplain' />
                                                </div>
                                            </div>
                                            <h3>Alcoholic Beverages</h3>
                                            <h4>5</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className='home-section-category-section-card'>
                                        <div className='home-section-category-section'>
                                            <div className='home-section-category-image-con'>
                                                <div className='home-section-category-image'>
                                                    <img src={ApparelAndFashion} alt='airplain' />
                                                </div>
                                            </div>
                                            <h3>Apparel And Fashion</h3>
                                            <h4>5</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className='home-section-category-section-card'>
                                        <div className='home-section-category-section'>
                                            <div className='home-section-category-image-con'>
                                                <div className='home-section-category-image'>
                                                    <img src={AutoAncillanires} alt='airplain' />
                                                </div>
                                            </div>
                                            <h3>Auto Ancillanires</h3>
                                            <h4>5</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeCategory