import React, { useEffect, useRef, useState } from 'react';
import './MarqueeComponent.css';
import axios from 'axios';

const MarqueeComponent = () => {
  const marqueeRef = useRef(null);

  useEffect(() => {
    const marqueeWidth = marqueeRef.current.querySelector('.marquee-content-primary').offsetWidth;
    document.documentElement.style.setProperty('--marquee-padding', marqueeWidth + 'px');
  }, []);

  const [hotDeals, setHotDeals] = useState([])
  console.log("hotDeals", hotDeals)
  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get('https://skdm.in/server/bhavinjavari/stock.php?action=stockFetchhotdeal');
        setHotDeals(response.data.list);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchCategoryData();
  }, []);

  return (
    <div className="marquee">
      <div className="marquee_text" ref={marqueeRef}>
        <ul className="marquee-content-primary">
          {
            hotDeals.map((elem) => {
              const { base_price, name, price, stock_type } = elem
              return (
                <>
                  <li>{name} <h3>{price}</h3> <h4>(0.00%)</h4></li>
                </>
              )
            })
          }
          {/* <li>amol minechem <h3>601.00</h3> <h4>(0.17 %)</h4></li>
          <li>anand i-power <h3>20.00</h3></li>
          <li>anglo french drugs <h3>745.00</h3> <h4>(-0.67 %)</h4></li>
          <li>apl metals  <h3>40.00</h3> <h4>(-2.44 %)</h4></li>
          <li>arkfin investments <h3>50.00</h3></li>
          <li>arohan <h3>202.00</h3> <h4>(1.00 %)</h4></li>
          <li>assam carbon <h3>265.00</h3> <h4>(1.92 %)</h4></li>
          <li>auckland international <h3>129.00</h3> <h4>(-0.77 %)</h4></li>
          <li>av thomas <h3>16,800.00</h3> <h4>(0.00 %)</h4></li>
          <li>axles india  <h3>610.00</h3> <h4>(1.67 %)</h4></li>
          <li>balmer lawrie <h3>202.00</h3> <h4>(1.00 %)</h4></li> */}
        </ul>
        {/* <ul className="marquee-content-secondary">
          <li>bharat hotels <h3>345.00</h3> <h4>(1.47 %)</h4></li>
          <li>bima mandi <h3>235.00</h3> <h4>(-2.08 %)</h4></li>
          <li>bira <h3>545.00</h3> <h4>(-0.91 %)</h4></li>
          <li>boat <h3>1,400.00</h3> <h4>(1.82 %)</h4></li>
          <li>c & s electric <h3>700.00</h3> <h4>(0.72 %)</h4></li>
          <li>cable corporation <h3>13.00</h3> <h4>(-0.76 %)</h4></li>
          <li>capgemini <h3>11,400.00</h3> <h4>(-0.87 %)</h4></li>
          <li>care health <h3>186.00</h3> <h4>(-1.59 %)</h4></li>
          <li>carrier aircon <h3>550.00</h3> <h4>(-0.90 %)</h4></li>
          <li>cial <h3>285.00</h3> <h4>(1.79 %)</h4></li>
          <li>csk <h3>170.00</h3> <h4>(-1.16 %)</h4></li>
          <li>dalmia refract <h3>260.00</h3> <h4>(1.96 %)</h4></li>
        </ul> */}
      </div>
      <div className='demo'>

      </div>
    </div>
  );
};

export default MarqueeComponent;
