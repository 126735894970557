import React from 'react';
import "../Order/RequestData.css";



const RequestData = () => {
    return (
        <>
            <section className="order-data-section">
                <div className="order-data-head-con">
                    <h2>Order / Enquiry </h2>
                </div>
                <div className="order-data-section-details">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="order-data-section-card-main-body">
                                <div className='order-data-section-card-con'>
                                    <div className='order-data-section-card-head-name-con'>
                                        <div className='order-data-section-card-head-details'>
                                            <div className='order-data-section-card-enquiry-for-con'>
                                                <h3>enquiry for</h3>
                                                <button>
                                                    Buy
                                                </button>
                                            </div>
                                            <div className='order-data-section-card-status'>
                                                <h4> status</h4>
                                                <button>
                                                    Enquiry
                                                </button>
                                            </div>
                                        </div>
                                        <div className='order-data-section-card-head-name'>
                                            <h2>WIPRO</h2>
                                        </div>
                                    </div>
                                    <div className='order-data-section-card-daetils-con'>
                                        <div className='order-data-section-first-con fistborder'>
                                            <div className='order-data-section-inside-con'>
                                                <h5>Average Price</h5>
                                                <h6>500.00</h6>
                                            </div>
                                            <div className='order-data-section-inside-con'>
                                                <h5>Total Qty</h5>
                                                <h6>3</h6>
                                            </div>
                                            <div className='order-data-section-inside-con'>
                                                <h5>Total Invt.</h5>
                                                <h6>15.00</h6>
                                            </div>
                                        </div>
                                        <div className='order-data-section-first-con'>
                                            <div className='order-data-section-inside-con'>
                                                <h5>P&L</h5>
                                                <h6>500.00</h6>
                                            </div>
                                            <div className='order-data-section-inside-con'>
                                                <h5>Date</h5>
                                                <h6>11/01/2000</h6>
                                            </div>
                                            <div className='order-data-section-inside-con'>
                                                <h5>Current Value</h5>
                                                <h6>15.00</h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default RequestData;
