import React from 'react';
import "../Css/Contact.css";
import { IoIosCall } from "react-icons/io";
import { IoIosMail } from "react-icons/io";
import { SiGooglemaps } from "react-icons/si";
import Footer from '../Component/Footer/Footer';
// import { MetaTags } from 'react-meta-tags';
import { Input, Form, Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import $ from "jquery";

const Contact = () => {
    const [form] = Form.useForm();

    const formSubmit = (values) => {
        var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#2698cf">32 DENTAL SQUARE</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#2698cf"><strong>Name:</strong></td><td style="text-align:left">' + values.name + '</td></tr><tr><td style="text-align:left;color:#2698cf"><strong>Contact No:</strong></td><td style="text-align:left">' + values.contact + '</td></tr><tr><td style="text-align:left;color:#2698cf"><strong>Message:</strong></td><td style="text-align:left">' + values.Message + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at 32 DENTAL SQUARE</p></div></body></html>';

        $.post("https://skdm.in/server/v1/send_lead_mail.php", {
            toEmail: "demo@gmail.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "demo",
            accountLeadSource: "https://32dentalsquare.in/",
            accountLeadName: values.name,
            accountLeadEmail: values.email,
            accountLeadPhone: values.contact,
        },

            function (dataa, status) {
                console.log('data :' + dataa);
                console.log("name:" + values);

            });

        alert("Your Form has Submitted Our team will contact with You  soon.");
        values.preventDefault()
        form.resetFields(); // Reset form fields after submission
        return false;
    };

    return (
        <>
            {/* <MetaTags>
                <title>Dentists Near Dadar | 32 Dental Square</title>
                <meta title="Dentists Near Dadar | 32 Dental Square" />
                <meta
                    name="description"
                    content="Find skilled dentists near Dadar for quality dental care, offering expertise in various treatments and convenient accessibility for patients."
                />
                <meta
                    name="keywords"
                    content="Best Dental Clinic In Dadar West,Dental Clinic In Dadar,Dental Clinic In Mumbai,Dentist In Dadar West,Cosmetic Dentistry Doctor In Dadar West,Dental Implant In Dadar West,Best Orthodontist In Dadar West,Pediatric Dentistry In Dadar West,Top Dentistry Dentist in Mumbai,Best Preventive Dentistry In Dadar,Digital Dentistry In Dadar,Best Dentist In Dadar West,Dentists Near Dadar"
                />
                <link rel="canonical" href="https://32dentalsquare.in/dentists-near-dadar" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Dentists Near Dadar | 32 Dental Square" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://32dentalsquare.in/dentists-near-dadar" />
                <meta
                    property="og:description"
                    content="Find skilled dentists near Dadar for quality dental care, offering expertise in various treatments and convenient accessibility for patients."
                />
                <meta
                    property="og:image"
                    content="https://32dentalsquare.in/"
                />
            </MetaTags> */}
            <section className="ProductList-Section-1 contact-banner">
                <div className="container">
                    <div className="ProductList-Section-con">
                        <div className="ProductList-Section-Text">
                            <div className="ProductList-Section-NavPage">
                                <span>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 1024 1024"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                                    </svg>
                                    Home
                                </span>
                                <h3>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path fill="none" d="M0 0h24v24H0V0z" />
                                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                                    </svg>
                                </h3>
                                <h2>Contact Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='Contact-Page-Form-And-Details-con'>
                <div className='container'>
                    <div className='Contact-Page-Details-con'>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='Contact-Page-Details-Info-con'>
                                    <div className='Contact-Page-Details-Head-con'>
                                        <h3>Contact with us</h3>
                                        <p>
                                            At Unlisted Guru, Bhavin Zaveri offers unparalleled expertise in unlisted stocks, ensuring you make informed investment decisions. Trust us for reliable market analysis, up-to-date information, and personalized investment strategies.
                                        </p>
                                    </div>

                                    <div className='Contact-Page-Details-contact-con'>
                                        <div className='Contact-Page-Details-Icons-Info-con'>
                                            <IoIosCall />
                                            <div className='Contact-Page-Details-Info'>
                                                <h4>Call us now</h4>
                                                <h5><a href="tel:+917666754194">7666-754-194</a></h5>
                                            </div>
                                        </div>

                                        <div className='Contact-Page-Details-Icons-Info-con'>
                                            <IoIosMail />
                                            <div className='Contact-Page-Details-Info'>
                                                <h4>Send email</h4>
                                                <h5><a href="mailto: Bhavinzaveri@gmail.com">Bhavinzaveri@gmail.com</a></h5>
                                            </div>
                                        </div>

                                        <div className='Contact-Page-Details-Icons-Info-con'>
                                            <SiGooglemaps />
                                            <div className='Contact-Page-Details-Info'>
                                                <h4>Our location</h4>
                                                <h5><a href='https://maps.app.goo.gl/Lvq9RKTbpykiKcnw7' target='blank'>43 ,joshi wadi ,chitale path, Bhavani Shankar Rd, off Bhavani Shankar Junior College, Mumbai, Maharashtra 400028</a></h5>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='col-md-5'>
                                <section className='Contact-page-Form-Con'>
                                    <div className='Contact-page-Form-Head-Con'>
                                        <h2>Get in Touch</h2>
                                    </div>
                                    <div className='Contact-Page-Form-Inputs-con'>
                                        <Form layout='vertical' onFinish={formSubmit} form={form}>
                                            <div className='Contact-Page-Form-Inputs'>
                                                <Form.Item name="name" label="Name">
                                                    <div className='Like-to-pay-form-con-Card-Info-Input'>
                                                        <Input placeholder='Enter your name' />
                                                    </div>
                                                </Form.Item>
                                            </div>
                                            <div className='Contact-Page-Form-Inputs'>
                                                <Form.Item name="contact" label="Enter number">
                                                    <div className='Like-to-pay-form-con-Card-Info-Input'>
                                                        <Input placeholder='Enter your phone number' />
                                                    </div>
                                                </Form.Item>
                                            </div>

                                            <div className='Contact-Page-Form-Inputs'>
                                                <Form.Item name="Message" label="Enter Message">
                                                    <div className='Like-to-pay-form-con-Card-Info-Input'>
                                                        <TextArea placeholder='Enter your message' />
                                                    </div>
                                                </Form.Item>
                                            </div>

                                            <div className='Contact-Page-Form-button'>
                                                <Form.Item>
                                                    <Button htmlType="submit">
                                                        Sent Message
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Contact-Page-Map-Section'>
                <div className='container'>
                    <div className='Contact-Page-Map-Section-con'>
                        <div className='Contact-Page-Map-Head-Section-con'>
                            <h2>View your location</h2>
                        </div>

                        <div className='Contact-Page-Map-Section-con-frem'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15077.660552624293!2d72.8091834!3d19.1332971!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c946e4282bd9%3A0x2781c048e4376933!2sShree%20Krishna%20Digital%20Marketing%20Services%20%7C%20Best%20Digital%20Marketing%20Company%20in%20Andheri%20%7C%20Digital%20Marketing%20Agency%20in%20Andheri!5e0!3m2!1sen!2sin!4v1716031712853!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact