import React, { useEffect, useState } from 'react';
import "../Home/HomeProduct.css";
import nse from "../../Images/Stock/nse.jpeg";
import BharatNidhi from "../../Images/Stock/BharatNidhi.jpg";
import hdb from "../../Images/Stock/hdb.jpeg";
import Polymatech from "../../Images/Stock/Polymatech.png";
import rrp from "../../Images/Stock/rrp.png";
import oyo from "../../Images/Stock/oyo.jpeg";
import { Pagination } from 'antd';
import Portfolio from "../../Images/data-management.png";
import { Select, Space } from 'antd';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { Link } from 'react-router-dom';
const HomeProduct = () => {

    const [productData, setProductData] = useState([])
    console.log("productData", productData)
    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const response = await axios.get('https://skdm.in/server/bhavinjavari/stock.php?action=stockFetch');
                setProductData(response.data.list);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchCategoryData();
    }, []);

    return (
        <>
            <section className='home-section-3'>
                <div className='container'>
                    <div className='home-section-3-con'>
                        <div className='home-section-3-heading-con'>
                            <h2>Popular <span>Unlisted</span> Shares</h2>
                            <p>"Discover Top Picks: Browse the Most Popular Unlisted Shares Sold Through Our Trusted Platform."</p>
                        </div>
                        <div className='home-section-3-filter-con-main'>
                            <div className='home-section-3-filter-con'>
                                <div className='home-section-3-filter-portfolio-con'>
                                    <div className='home-section-3-filter-portfolio-image-con'>
                                        <img src={Portfolio} alt='Portfolio' />
                                    </div>
                                    <h3>Portfolio</h3>
                                </div>
                            </div>
                        </div>
                        <div className='home-section-3-Card-main-con'>
                            <div className='row'>
                                {
                                    productData && productData.map((elem) => {
                                        const { base_price, image, name,slug } = elem
                                        return (
                                            <>
                                                <div className='col-md-4'>
                                                    <div className='stock-card-main-con-card-con'>
                                                        <Link to={`/stock-deails/${slug}`}>
                                                            <div className='stock-card-main-con'>
                                                                <div className='stock-card-image-con'>
                                                                    <img src={nse} alt='nse' />
                                                                </div>
                                                                <div className='stock-card-details-con'>
                                                                    <h3>NSE India Limited Unlisted Shares</h3>
                                                                    <h4>₹6500</h4>
                                                                    <div className='stock-card-details-info-con'>
                                                                        <h5>( +0.00 )</h5>
                                                                        <h5>( 0% )</h5>
                                                                        <h6>15D</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                {/* <div className='col-md-4'>
                                    <div className='stock-card-main-con'>
                                        <div className='stock-card-image-con'>
                                            <img src={BharatNidhi} alt='nse' />
                                        </div>
                                        <div className='stock-card-details-con'>
                                            <h3>NSE India Limited Unlisted Shares</h3>
                                            <h4>₹6500</h4>
                                            <div className='stock-card-details-info-con'>
                                                <h5>( +0.00 )</h5>
                                                <h5>( 0% )</h5>
                                                <h6>15D</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='stock-card-main-con'>
                                        <div className='stock-card-image-con'>
                                            <img src={hdb} alt='nse' />
                                        </div>
                                        <div className='stock-card-details-con'>
                                            <h3>NSE India Limited Unlisted Shares</h3>
                                            <h4>₹6500</h4>
                                            <div className='stock-card-details-info-con'>
                                                <h5>( +0.00 )</h5>
                                                <h5>( 0% )</h5>
                                                <h6>15D</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='stock-card-main-con'>
                                        <div className='stock-card-image-con'>
                                            <img src={Polymatech} alt='nse' />
                                        </div>
                                        <div className='stock-card-details-con'>
                                            <h3>NSE India Limited Unlisted Shares</h3>
                                            <h4>₹6500</h4>
                                            <div className='stock-card-details-info-con'>
                                                <h5>( +0.00 )</h5>
                                                <h5>( 0% )</h5>
                                                <h6>15D</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='stock-card-main-con'>
                                        <div className='stock-card-image-con'>
                                            <img src={rrp} alt='nse' />
                                        </div>
                                        <div className='stock-card-details-con'>
                                            <h3>NSE India Limited Unlisted Shares</h3>
                                            <h4>₹6500</h4>
                                            <div className='stock-card-details-info-con'>
                                                <h5>( +0.00 )</h5>
                                                <h5>( 0% )</h5>
                                                <h6>15D</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='stock-card-main-con'>
                                        <div className='stock-card-image-con'>
                                            <img src={oyo} alt='nse' />
                                        </div>
                                        <div className='stock-card-details-con'>
                                            <h3>NSE India Limited Unlisted Shares</h3>
                                            <h4>₹6500</h4>
                                            <div className='stock-card-details-info-con'>
                                                <h5>( +0.00 )</h5>
                                                <h5>( 0% )</h5>
                                                <h6>15D</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className='Home-section-3-stocks-btn-con'>
                            <button>
                                View More
                            </button>
                        </div> */}
                        <div className='home-section-3-stock-pagination-con'>
                            <Pagination defaultCurrent={6} total={500} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeProduct