import React from 'react';
import "../Home/Process.css";

const Process = () => {
    return (
        <>
            <section className='home-section-process-main-con'>
                <div className='container'>
                    <div className='home-section-head-text'>
                        <h2>Process to <span> Buy Unlisted Shares </span></h2>
                        <p> Recommended for those interested in dealing with unlisted shares.</p>
                    </div>

                    <div className='home-section-process-con'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='home-section-process-card-con'>
                                    <div className='home-section-process-card-number'>
                                        <h2>1</h2>
                                    </div>
                                    <h3>Contact Us</h3>
                                    <p>Connect with our RMs and Get Best Unlisted Share Prices.</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='home-section-process-card-con'>
                                    <div className='home-section-process-card-number'>
                                        <h2>2</h2>
                                    </div>
                                    <h3>Deal Processing</h3>
                                    <p>First, the buyer sends payment to our bank Then UnlistedZone team starts processing.</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='home-section-process-card-con'>
                                    <div className='home-section-process-card-number'>
                                        <h2>3</h2>
                                    </div>
                                    <h3>Deal Completion</h3>
                                    <p>After Getting the payment our Team Transfer Shares with 24 hrs.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Process;