import React from 'react';
import "../Footer/Footer.css";
// import logo from '../../Images/Logo/32_dental_Logo.png'
import { Link } from 'react-router-dom';
import { MdAddIcCall } from 'react-icons/md';
import { GoMail } from 'react-icons/go';


const Footer = () => {
  return (
    <>
      <div className='footer pt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4 border-end border-bottom'>
              <div className="address_main">
                <div class="footer-logo">
                  <Link to="/">
                    {/* <img src={logo} class="img-responsive" alt="footer_logo" /> */}
                  </Link>
                </div>
                <p>
                  Explore a wealth of opportunities with Bhavin Zaveri at Unlisted Guru. Specializing in unlisted stocks, we provide expert insights and comprehensive services to help you navigate the unlisted securities market.
                </p>
              </div>
            </div>
            <div className='col-md-8 border-bottom'>
              <div className='row'>
                <div className='col-lg-3'>
                  <div className='footer-head'>
                    <h3>Quick Links</h3>
                  </div>
                  <div className='footer-link'>
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/about">About</Link></li>
                      <li><Link to="/stocks">stocks</Link></li>
                      <li><Link to="/contact">Contact</Link></li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='footer-head'>
                    <h3>Category</h3>
                  </div>
                  <div className='footer-service'>
                    {/* <ul>
                      {
                        ServiceList.map((elem) => {
                          return (
                            <>
                              <li><Link to={elem.Slugs}>{elem.ServiceName}</Link></li>
                            </>
                          )
                        })
                      }
                    </ul> */}
                    <ul>
                      <li>Agriculture</li>
                      <li>Agro Chemicals</li>
                      <li>Airpot</li>
                      <li>Alcoholic Beverages</li>
                      <li>Apparel And Fashion</li>
                      <li>Auto Ancillanires</li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-5'>
                  <div className='footer-head'>
                    <h3>Contact US</h3>
                  </div>
                  <div className='footer-add'>
                    <ul>
                      <li><a href="https://goo.gl/maps/kWwvaWUiQbZ3qMYPA" target='blank'><p> Office No 301, MOHID HEIGHTS, opposite Mahada, signal, near kokilaben hospital, Suresh Nagar, Andheri West, Mumbai, Maharashtra 400053</p></a></li>
                    </ul>
                  </div>
                  <div className="footer-icon">
                    <ul>
                      <li><i><MdAddIcCall /></i><span>Call us : </span> <a href="tel:+917666754194">+91 7666-754-194</a></li>
                      <li><i><GoMail /></i><span>Email :</span><a href="mailto: Bhavinzaveri@gmail.com">Bhavinzaveri@gmail.com</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row p-3'>
            <div className='col-md-6'>
              <div className="copyright-text">
                <p>Copyright &copy; 2024, All Right Reserved <a href="index.html">
                  <strong>Bhavin zaveri</strong></a></p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="footer-menu">
                <div className="copyright-text text-center">
                  <p>Developed by<a href="https://skdm.in/" target='blank'>
                    <strong> Shree Krishna Digital Marketing</strong></a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
